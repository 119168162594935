<template>
  <EditCsvDownloads></EditCsvDownloads>
</template>

<script>
import EditCsvDownloads from "@/components/reports/EditCsvDownloads";
export default {
  name: "Edit",
  components: { EditCsvDownloads }
};
</script>

<style scoped></style>
